module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-translate-urls/gatsby-browser.js'),
      options: {"plugins":[],"translations":{"en":{"urls.services":"services","urls.careers":"careers","urls.privacy":"privacy","urls.ai-service":"services/generative-ai/development-company","urls.ai-development":"services/machine-learning-services/ai-deployment","urls.data":"services/data-engineering/data-engineering-services","urls.blogs":"insights"}},"defaultLocale":"en","prefix":"urls."},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
